
import { Component, Vue } from "vue-property-decorator";

@Component({
	components: {},
})
export default class Profile extends Vue {
	get username() {
		return this.$store.state.user;
	}

	get initial() {
		return String(this.username).charAt(0);
	}
}
